<template>
    <div class="cu-questions-screen">
        <div class="row">
            <div class="col-6 d-flex align-items-center">
                <div class="d-flex align-items-center">
                    <div class="qu-icon-radius d-flex align-items-center">
                        <feather-icon icon="PlusIcon" size="17" class="qu-icon-grey" />
                    </div>
                    <p class="fs-105 fs-options-1 qu-fw-400 mb-0 ml-1" style="margin-top: 4px">
                        MCQs
                    </p>
                    <!-- <div class="align-items-center border-grey qu-fc-color curser-pointer m-1">
                        <span class="text-nowrap">Preview Questions</span>
                    </div> -->
                    <!-- <ExportQuestionForGpt :questions="questions" /> -->

                    <PreviewQuestions :questions="questions" />
                </div>
            </div>
            <div class="col-6">
                <div class="
                        d-flex
                        flex-row
                        gap-25
                        align-items-center
                        justify-content-end
                    ">
                    <span v-click-outside="hideFields" id="hide-show-fields">
                        <div class="qu-fc-color text-nowrap" @click="hideShowField" id="hide-show-btn">
                            Show/Hide Fields
                            <feather-icon icon="ChevronDownIcon" />
                        </div>
                        <div v-if="isFieldDropdown" class="position-absolute p-1" style="
                                width: 200px;
                                height: 110px;
                                background-color: #fff;
                                border-radius: 10px;
                                margin-top: 5px;
                            ">
                            <div class="d-flex">
                                <b-form-checkbox v-model="isTitle">
                                </b-form-checkbox>
                                Title
                            </div>
                            <div class="d-flex mt-1">
                                <b-form-checkbox v-model="isDescription">
                                </b-form-checkbox>
                                Description
                            </div>
                            <div class="d-flex mt-1">
                                <b-form-checkbox v-model="isOption">
                                </b-form-checkbox>
                                Option
                            </div>
                        </div>
                    </span>
                    <ExportQuestionForGpt :questions="questions" />
                    <AddQuestionGptResponse :error="errorMsg" @createQuestionJson="createQuestionJson"
                        @resetGptModel="resetGptModel" />

                    <div class="
                            align-items-center
                            border-grey
                            qu-fc-color
                            curser-pointer
                            d-flex
                        " @click="confirmDelete">
                        <feather-icon icon="TrashIcon" />
                        <span class="text-nowrap qu-margin-left-10">Delete</span>
                    </div>
                    <div>
                        <b-input-group>
                            <b-button variant="primary" @click="addNewQuestion">
                                New
                            </b-button>
                        </b-input-group>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-2 overflow-auto" style="padding-bottom:5px;">
            <div class="table-container custom-table-questions">
                <div class="table-header" style="border-top: 1px solid #ebe9f1">
                    <div v-if="!isStickyNumber" class="header-cell cu-padding" style="width: 120px"></div>
                    <div v-if="!isStickyNumber" class="
                            header-cell
                            text-center
                            cu-padding
                            position-relative
                        " style="width: 150px">
                        <img src="/setting1.png" alt="" class="table-heading-margin img-width" />
                        <span>Setting</span>
                        <span class="position-absolute" style="right: 5px">
                            <feather-icon class="curser-pointer" @click="updateStickFunction" style="color: #7367f0"
                                icon="BookmarkIcon" />
                        </span>
                    </div>
                    <div v-if="isStickyNumber || isStickyTitle" :class="{
                        'sticky-column': isStickyNumber || isStickyTitle,
                    }">
                        <div v-if="isStickyNumber" class="header-cell cu-padding" style="width: 120px"
                            :class="{ 'sticky-column': isStickyNumber }"></div>
                        <div v-if="isStickyNumber" class="
                                header-cell
                                text-center
                                cu-padding
                                position-relative
                            " style="width: 150px">
                            <img src="/setting1.png" alt="" class="table-heading-margin img-width" />
                            <span>Setting</span>
                            <span class="position-absolute" style="right: 5px">
                                <feather-icon class="curser-pointer" @click="updateStickFunction" style="color: #7367f0"
                                    icon="BookmarkIcon" fill="#7367f0" />
                            </span>
                        </div>
                        <div class="
                                header-cell
                                text-center
                                cu-padding
                                position-relative
                            " style="width: 450px" v-if="isStickyTitle && isTitle">
                            <img src="/title.png" alt="" class="table-heading-margin img-width" />
                            <span>Title</span>
                            <span class="position-absolute" style="right: 5px">
                                <feather-icon class="curser-pointer" @click="updateStickTitle" style="color: #7367f0"
                                    icon="BookmarkIcon" fill="#7367f0" />
                            </span>
                        </div>
                    </div>
                    <div class="
                            header-cell
                            text-center
                            cu-padding
                            position-relative
                        " style="width: 450px" v-if="!isStickyTitle && isTitle">
                        <img src="/title.png" alt="" class="table-heading-margin img-width" />
                        <span>Title</span>
                        <span class="position-absolute" style="right: 5px">
                            <feather-icon class="curser-pointer" @click="updateStickTitle" style="color: #7367f0"
                                icon="BookmarkIcon" />
                        </span>
                    </div>
                    <div class="
                            header-cell
                            text-center
                            position-relative
                            cu-padding
                        " :class="{ 'sticky-column': isStickyDescription }" v-if="isDescription"
                        v-for="(n, index) in descriptionCount" :key="index + 'descth'" :style="{ width: '450px' }">
                        <img v-if="index == 0" src="/desc.png" alt="" class="table-heading-margin img-width" />
                        <span v-if="index == 0">Descriptions</span>
                        <span class="position-absolute" style="right: 5px">
                            <feather-icon v-if="index >= 0" class="curser-pointer" @click="addDescription"
                                style="color: #7367f0" icon="PlusIcon" />
                            <feather-icon v-if="index > 0" class="curser-pointer" @click="removeDescription(index)"
                                style="color: red" icon="TrashIcon" />
                        </span>
                    </div>
                    <div class="
                            header-cell
                            text-centers
                            position-relative
                            cu-padding
                        " v-if="isOption" v-for="(n, index) in optionCount" :key="index + 'optth'"
                        :style="{ width: '520px' }">
                        <img v-if="index == 0" src="/desc.png" alt="" class="table-heading-margin img-width" />
                        <span v-if="index == 0">Options</span>
                        <span class="position-absolute" style="right: 5px">
                            <feather-icon v-if="index >= 0" class="curser-pointer" @click="addOption"
                                style="color: #7367f0" icon="PlusIcon" />
                            <feather-icon v-if="index > 0" class="curser-pointer" @click="removeOption(index)"
                                style="color: red" icon="TrashIcon" />
                        </span>
                    </div>
                </div>
                <div class="table-body">
                    <div class="d-flex" style="border-top: 1px solid #ebe9f1">
                        <div v-if="!isStickyNumber" class="cell fs-0625" style="width: 60px">
                            <b-form-checkbox class="m-auto" style="width: fit-content"
                                v-on:change="checkUncheckQuestion()" v-model="tableCheckbox"></b-form-checkbox>
                        </div>
                        <div v-if="!isStickyNumber" class="cell fs-0625" style="width: 60px">
                            <p class="m-auto mb-0" style="width: fit-content">
                                #
                            </p>
                        </div>
                        <div v-if="!isStickyNumber" class="cell fs-0625 justify-content-center cu-txt-color-font-weight"
                            style="width: 150px">
                            Theme
                        </div>
                        <div v-if="isStickyNumber || isStickyTitle" class="d-flex flex-row" :class="{
                            'sticky-column':
                                isStickyNumber || isStickyTitle,
                        }">
                            <div v-if="isStickyNumber" class="cell fs-0625" style="width: 60px">
                                <b-form-checkbox class="m-auto" style="width: fit-content"
                                    v-on:change="checkUncheckQuestion()" v-model="tableCheckbox"></b-form-checkbox>
                            </div>
                            <div v-if="isStickyNumber" class="cell fs-0625" style="width: 60px">
                                <p class="m-auto mb-0" style="width: fit-content">
                                    #
                                </p>
                            </div>
                            <div v-if="isStickyNumber"
                                class="cell fs-0625 justify-content-center cu-txt-color-font-weight"
                                style="width: 150px">
                                Theme
                            </div>
                            <div v-if="isStickyTitle" class="d-flex flex-row">
                                <div class="cell fs-0625 justify-content-center cu-txt-color-font-weight" v-if="isTitle"
                                    style="width: 225px">
                                    Question title
                                </div>
                                <div class="cell position-relative fs-0625 justify-content-center cu-txt-color-font-weight"
                                    style="width: 225px" v-if="isTitle">
                                    TTS
                                    <span class="position-absolute" style="right: 5px">
                                        <feather-icon class="curser-pointer" @click="copyQuestionTts"
                                            style="color: #47464a" icon="CopyIcon" />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div v-if="!isStickyTitle" class="d-flex flex-row" :class="{ 'sticky-column': isStickyTitle }">
                            <div class="cell fs-0625 justify-content-center cu-txt-color-font-weight" v-if="isTitle"
                                style="width: 225px">
                                Question title
                            </div>
                            <div class="cell position-relative fs-0625 justify-content-center cu-txt-color-font-weight"
                                style="width: 225px" v-if="isTitle">
                                TTS
                                <span class="position-absolute" style="right: 5px">
                                    <feather-icon class="curser-pointer" @click="copyQuestionTts" style="color: #47464a"
                                        icon="CopyIcon" />
                                </span>
                            </div>
                        </div>

                        <div v-for="(i, index) in descriptionCount" v-if="isDescription" class="d-flex flex-row"
                            :class="{ 'sticky-column': isStickyDescription }">
                            <div class="cell fs-0625 justify-content-center cu-txt-color-font-weight"
                                style="width: 225px">
                                Description text {{ i }}
                            </div>
                            <div class="cell position-relative justify-content-center cu-txt-color-font-weight"
                                style="font-size: 10px; width: 225px">
                                Description TTS {{ i }}
                                <span class="position-absolute" style="right: 5px">
                                    <feather-icon class="curser-pointer" @click="copyDescitpionTts(index)"
                                        style="color: #47464a" icon="CopyIcon" />
                                </span>
                            </div>
                        </div>
                        <template v-if="isOption" v-for="(j, index) in optionCount">
                            <div class="cell fs-0625 justify-content-center cu-txt-color-font-weight"
                                style="width: 225px">
                                Option text {{ j }}
                            </div>
                            <div class="cell position-relative fs-0625 justify-content-center cu-txt-color-font-weight"
                                style="width: 225px">
                                Option text TTS {{ j }}
                                <span class="position-absolute" style="right: 5px">
                                    <feather-icon class="curser-pointer" @click="copyOptionTts(index)"
                                        style="color: #47464a" icon="CopyIcon" />
                                </span>
                            </div>
                            <div class="cell fs-0625 justify-content-center cu-txt-color-font-weight"
                                style="width: 70px">
                                Correct {{ j }}
                            </div>
                        </template>
                    </div>
                    <draggable v-model="questions" group="people" @start="onDragStart" @end="onDragEnd"
                        handle=".handle">
                        <div v-for="(question, questionIndex) in questions" :key="questionIndex + 'question'"
                            class="d-flex input-cells hover-question-row" style="border-top: 1px solid #ebe9f1">
                            <div v-if="!isStickyNumber" class="cell position-relative" style="width: 60px">
                                <feather-icon icon="MoveIcon" style="
                                        position: absolute;
                                        top: 2px;
                                        left: 2px;
                                    " size="12" class="handle move-duplicate-hover" />
                                <feather-icon icon="CopyIcon" @click="duplicateQuestion(question)" style="
                                        position: absolute;
                                        bottom: 5px;
                                        left: 2px;
                                    " size="14" class="move-duplicate-hover" />
                                <b-form-checkbox v-model="question.checkbox" @change="toggleSelectedQuestion()"
                                    class="m-auto" style="width: fit-content"></b-form-checkbox>
                            </div>
                            <div v-if="!isStickyNumber" class="cell" style="width: 60px">
                                <div class="cu-tooltip">
                                    {{ question.questionNo }}
                                </div>
                            </div>
                            <div v-if="!isStickyNumber" class="cell" style="width: 150px">
                                <b-form-select v-model="question.theme" :options="formattedThemes"></b-form-select>
                            </div>
                            <div v-if="isStickyNumber || isStickyTitle" class="d-flex flex-row" :class="{
                                'sticky-column':
                                    isStickyNumber || isStickyTitle,
                            }">
                                <div v-if="isStickyNumber" class="cell position-relative" style="width: 60px">
                                    <feather-icon icon="MoveIcon" style="
                                            position: absolute;
                                            top: 2px;
                                            left: 2px;
                                        " size="12" class="handle move-duplicate-hover" />
                                    <feather-icon icon="CopyIcon" @click="duplicateQuestion(question)" style="
                                            position: absolute;
                                            bottom: 5px;
                                            left: 2px;
                                        " size="14" class="move-duplicate-hover" />
                                    <b-form-checkbox v-model="question.checkbox" @change="toggleSelectedQuestion()"
                                        class="m-auto" style="width: fit-content"></b-form-checkbox>
                                </div>
                                <div v-if="isStickyNumber" class="cell" style="width: 60px">
                                    <div class="cu-tooltip">
                                        {{ question.questionNo }}
                                    </div>
                                </div>
                                <div v-if="isStickyNumber" class="cell" style="width: 150px">
                                    <b-form-select v-model="question.theme" :options="formattedThemes"></b-form-select>
                                </div>
                                <div v-if="isStickyTitle" class="d-flex flex-row">
                                    <div contenteditable="false" class="cell p-0 position-relative" v-if="isTitle"
                                        style="width: 225px">
                                        <div class="qu-remove-border editable-div single-line" contenteditable="true"
                                            v-html="question.title" @focus="onFocus"
                                            @blur="onBlur($event, questionIndex, 'title')">
                                        </div>
                                    </div>
                                    <div class="cell p-0 position-relative" v-if="isTitle" style="width: 225px">
                                        <div class="qu-remove-border editable-div single-line" contenteditable="true"
                                            v-html="question.titleTts" @focus="onFocus"
                                            @blur="onBlur($event, questionIndex, 'titleTts')">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="!isStickyTitle" class="d-flex flex-row">
                                <div class="cell p-0 position-relative" v-if="isTitle" style="width: 225px">
                                    <div class="qu-remove-border editable-div single-line" contenteditable="true"
                                        v-html="question.title" @focus="onFocus"
                                        @blur="onBlur($event, questionIndex, 'title')">
                                    </div>
                                </div>
                                <div class="cell p-0 position-relative" v-if="isTitle" style="width: 225px">
                                    <div class="qu-remove-border editable-div single-line" contenteditable="true"
                                        v-html="question.titleTts" @focus="onFocus"
                                        @blur="onBlur($event, questionIndex, 'titleTts')">
                                    </div>
                                </div>
                            </div>
                            <div v-for="(
                                    desc, descIndex
                                ) in question.descriptions" v-if="isDescription" class="d-flex flex-row" :class="{
                                    'sticky-column': isStickyDescription,
                                }">
                                <div class="cell p-0 position-relative" style="width: 225px">
                                    <div class="qu-remove-border editable-div single-line" contenteditable="true"
                                        v-html="desc.title" @focus="onFocus"
                                        @blur="onBlur($event, questionIndex, 'desc', descIndex, 'title')">
                                    </div>
                                    <b-dropdown
                                        v-if="desc.image == null || desc.image.length == 0 || desc.image[0].image == null"
                                        class="position-absolute" style="right:-17px" variant="link" no-caret>
                                        <template #button-content>
                                            <feather-icon icon="UploadIcon" size="20"
                                                class="qu-icon-grey curser-pointer"
                                                @click="showFullScreenModal('', questionIndex, descIndex, 'desc')" />
                                        </template>
                                        <b-dropdown-item
                                            @click="triggerFileInput(imgUploadQuestion, imgUploadoptionIndex, uploadType)">
                                            <feather-icon icon="UploadIcon" />
                                            <span class="align-middle ml-50">Upload</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="showGalleryImages">
                                            <feather-icon icon="ImageIcon" />
                                            <span class="align-middle ml-50">Gallery Images</span>
                                        </b-dropdown-item>
                                    </b-dropdown>
                                    <input :ref="'ref_Input' + descIndex" type="file"
                                        accept="image/x-png,image/gif,image/jpeg" class="d-none"
                                        @change="ImageRendererItems" />
                                    <div v-for="(
                                    img
                                ) in desc.image" class="position-absolute" style="right: 5px;">
                                        <img @click="showFullScreenModal(img.url, questionIndex, descIndex, 'desc')"
                                            :src="img.url" height="30" :alt="img.name" />
                                    </div>
                                </div>
                                <div class="cell p-0 position-relative" style="width: 225px">
                                    <div class="qu-remove-border editable-div single-line" contenteditable="true"
                                        v-html="desc.tts" @focus="onFocus"
                                        @blur="onBlur($event, questionIndex, 'desc', descIndex, 'tts')">
                                    </div>
                                </div>
                            </div>
                            <template v-if="isOption" v-for="(opt, optIndex) in question.options">
                                <div class="cell p-0 position-relative" style="width: 225px">
                                    <div class="qu-remove-border editable-div single-line" contenteditable="true"
                                        v-html="opt.title" @focus="onFocus"
                                        @blur="onBlur($event, questionIndex, 'opt', optIndex, 'title')">
                                    </div>
                                    <input :ref="'ref_Input_opt' + optIndex" type="file"
                                        accept="image/x-png,image/gif,image/jpeg" class="d-none"
                                        @change="ImageRendererItems" />
                                    <b-dropdown v-if="opt.image == null || opt.image == ''" class="position-absolute"
                                        style="right:-17px" variant="link" no-caret>
                                        <template #button-content>
                                            <feather-icon icon="UploadIcon" size="20"
                                                class="qu-icon-grey curser-pointer"
                                                @click="showFullScreenModal('', questionIndex, optIndex, 'opt')" />
                                        </template>
                                        <b-dropdown-item
                                            @click="triggerFileInput(imgUploadQuestion, imgUploadoptionIndex, uploadType)">
                                            <feather-icon icon="UploadIcon" />
                                            <span class="align-middle ml-50">Upload</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="showGalleryImages">
                                            <feather-icon icon="ImageIcon" />
                                            <span class="align-middle ml-50">Gallery Images</span>
                                        </b-dropdown-item>
                                    </b-dropdown>
                                    <img v-else class="position-absolute" style="right: 5px;"
                                        @click="showFullScreenModal(opt.image, questionIndex, optIndex, 'opt')"
                                        :src="opt.image" height="30" :alt="opt.name" />
                                </div>
                                <div class="cell p-0 position-relative" style="width: 225px">
                                    <div class="qu-remove-border editable-div single-line" contenteditable="true"
                                        v-html="opt.tts" @focus="onFocus"
                                        @blur="onBlur($event, questionIndex, 'opt', optIndex, 'tts')">
                                    </div>
                                </div>
                                <div class="cell" style="width: 70px">
                                    <b-form-checkbox v-model="opt.correct" class="m-auto"
                                        style="width: fit-content"></b-form-checkbox>
                                </div>
                            </template>
                        </div>
                    </draggable>
                </div>
            </div>
        </div>
        <div>
            <div class="d-flex" style="border: 1px solid #ebe9f1;margin-top:-5px;">
                <div class="py-1 px-1 w-100 curser-pointer d-flex" style="font-size: 1rem">
                    <div @click="addNewQuestion" class="d-flex">
                        <feather-icon icon="PlusIcon" size="20" class="qu-icon-grey" />
                        New
                    </div>
                </div>
            </div>
            <SaveQuestion :updateQuestionsChanges="updateQuestionsChanges" :newQuestion="newQuestion"
                :questions="questions" :originalQuestions="originalQuestions" @saveQuestions="saveQuestions" />
        </div>

        <SeriesQuestions />
        <b-modal v-model="showFullScreen" size="lg">
            <b-button variant="primary" @click="triggerFileInput(imgUploadQuestion, imgUploadoptionIndex, uploadType)">
                Upload
            </b-button>
            <b-button class="ml-1" variant="primary" @click="showGalleryImages">
                Gallery Images
            </b-button>
            <b-button v-if="imageUrl" class="ml-1" variant="danger" @click="removeImage">
                Remove
            </b-button>
            <div class="text-center mt-2">
                <img v-if="imageUrl" :src="imageUrl" alt="Fullscreen" style="height: auto;
    max-width: 100%;" />
                <feather-icon v-else @click="triggerFileInput(imgUploadQuestion, imgUploadoptionIndex, uploadType)"
                    icon="UploadIcon" size="50" class="qu-icon-grey curser-pointer " />
            </div>
        </b-modal>
        <zape-gallery-model :props_hideShowModel="showImage" @modelClose="modelClose"
            @saveCategoryImage="saveCategoryImage" />
    </div>
</template>

<script>
import {
    BCard,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BFormGroup,
    BSpinner,
    BRow,
    BCol,
    BInputGroup,
    BIcon,
    BFormCheckbox,
    BFormSelect,
    BTooltip,
    BFormTextarea,
} from "bootstrap-vue";
import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ClickOutside from "vue-click-outside";
import draggable from "vuedraggable";
import PreviewQuestions from "./PreviewQuestions.vue";
export default {
    components: {
        draggable,
        SaveQuestion: () => import("./SaveQuestion.vue"),
        SeriesQuestions: () => import("./SeriesQuestions.vue"),
        AddQuestionGptResponse: () => import("./AddQuestionGptResponse.vue"),
        ExportQuestionForGpt: () => import("./ExportQuestionForGpt.vue"),
        ZapeGalleryModel: () => import("../Model/ZapeGalleryModel.vue"),
        BButton,
        PreviewQuestions,
        BFormInput,
        BFormGroup,
        BInputGroup,
        BTable,
        BDropdown,
        BDropdownItem,
        BOverlay,
        BSpinner,
        BCard,
        BRow,
        BCol,
        BIcon,
        ToastificationContent,
        BFormCheckbox,
        BFormSelect,
        BTooltip,
        BFormTextarea,
    },
    directives: {
        ClickOutside,
    },
    data() {
        return {
            alertMessageToast: "",
            show: false,
            bearerToken: "",
            questions: [],
            themes: [],
            formatType: [
                { value: "text", text: "text" },
                { value: "image", text: "image" },
            ],
            descriptionCount: 1,
            optionCount: 1,
            tableCheckbox: false,
            originalQuestions: [],
            isFieldDropdown: false,
            isTitle: true,
            isDescription: true,
            isOption: true,
            isSelectedQuestion: {},
            isStickyNumber: false,
            isStickyTitle: false,
            isStickyDescription: false,
            drag: false,
            dragIndex: null,
            dropIndex: null,
            errorMsg: "",
            imgUploadQuestion: null,
            imgUploadoptionIndex: null,
            imageUrl: null,
            uploadType: null,
            showFullScreen: false,
            showImage: false,
        };
    },
    computed: {
        formattedThemes() {
            return this.themes.map((theme) => ({
                value: theme.name,
                text: theme.name,
            }));
        },
    },
    mounted() {
        window.addEventListener("beforeunload", this.handleBeforeUnload);
        this.bearerToken = "Bearer " + localStorage.getItem("_t");
        this.fetch();
    },
    beforeDestroy() {
        window.removeEventListener("beforeunload", this.handleBeforeUnload);
    },
    methods: {
        copyDescitpionTts(index) {
            this.questions.forEach((question) => {
                question.descriptions[index].tts = question.descriptions[index].title;
            });
        },
        copyOptionTts(index) {
            this.questions.forEach((question) => {
                question.options[index].tts = question.options[index].title;
            });
        },
        copyQuestionTts() {
            this.questions.forEach((question) => {
                question.titleTts = question.title;
            });
        },
        removeImage() {
            if (this.uploadType == 'desc') {
                let imageObject = [];
                this.questions[this.imgUploadQuestion].descriptions[this.imgUploadoptionIndex].image = imageObject;
            } else {
                this.questions[this.imgUploadQuestion].options[this.imgUploadoptionIndex].image = null;
            }
            this.imageUrl = '';
        },
        showGalleryImages() {
            this.showImage = true;
        },
        modelClose(modelState) {
            this.showImage = modelState;
        },
        saveCategoryImage(categoryImage) {
            if (this.uploadType == 'desc') {
                let imageObject = [{
                    name: 'gallery-image',
                    url: categoryImage,
                }]
                this.questions[this.imgUploadQuestion].descriptions[this.imgUploadoptionIndex].image = imageObject;
            } else {
                this.questions[this.imgUploadQuestion].options[this.imgUploadoptionIndex].image = categoryImage;
            }
            this.imageUrl = categoryImage;

        },
        showFullScreenModal(url, questionIndex, index, type) {
            this.imageUrl = url;
            this.imgUploadQuestion = questionIndex;
            this.imgUploadoptionIndex = index;
            this.uploadType = type;
            if (url) {
                this.showFullScreen = true;
            }
        },
        triggerFileInput(questionIndex, index, type) {
            this.imgUploadQuestion = questionIndex;
            this.imgUploadoptionIndex = index;
            this.uploadType = type;
            if (type == 'desc') {
                this.$refs['ref_Input' + index][0].click();
            } else {
                this.$refs['ref_Input_opt' + index][0].click();
            }
        },
        async uploadFile(file, url, key) {
            try {
                const response = await axios.put(url, file, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': file.type,
                    },
                });

                if (response.status !== 200) {
                    console.error('Error uploading file:', response);
                }
                let s3Url = 'https://zape02.s3.ap-southeast-1.amazonaws.com/' + key;
                if (this.uploadType == 'desc') {
                    let imageObject = [{
                        name: file.name,
                        url: s3Url,
                    }];
                    this.questions[this.imgUploadQuestion].descriptions[this.imgUploadoptionIndex].image = imageObject;
                } else {
                    this.questions[this.imgUploadQuestion].options[this.imgUploadoptionIndex].image = s3Url;
                }
                this.imageUrl = s3Url;
                this.createGalleryImage(s3Url, file.name)
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        },
        async createGalleryImage(s3Url, fileName) {
            try {
                let galleryObj = {
                    name: fileName,
                    url: s3Url
                }
                await axios.post(process.env.VUE_APP_API_URL + '/gallery/image/create', galleryObj, {
                    headers: {
                        Authorization: this.bearerToken,
                    },
                });
            } catch (error) {
                console.error('Error saving image to gallery', error);
            }
        },
        async ImageRendererItems(event) {
            const file = event.target.files[0];
            if (file) {
                const fileType = file.type;
                const fileExtension = this.getFileExtension(fileType);
                try {
                    const response = await axios.post(process.env.VUE_APP_API_URL + '/s3/generate-presigned-url', {
                        type: file.type,
                        ext: fileExtension
                    }, {
                        headers: {
                            Authorization: this.bearerToken,
                        },
                    });
                    const { url, key } = response.data;
                    await this.uploadFile(file, url, key);
                } catch (error) {
                    console.error('Error getting pre-signed URL:', error);
                }
            }
        },

        getFileExtension(mimeType) {
            const mimeToExtension = {
                'image/jpeg': 'jpg',
                'image/png': 'png',
                'image/gif': 'gif',
            };
            return mimeToExtension[mimeType] || 'png';
        },

        resetGptModel() {
            this.errorMsg = "";
        },

        async createQuestionJson(text) {
            const payload = { text: text };
            try {
                const resp = await axios.post(process.env.VUE_APP_API_URL + `/question/text/json`, payload, {
                    headers: { Authorization: this.bearerToken },
                });
                let gptQuestion = resp.data;
                if (gptQuestion.length > 0) {
                    const { questionNo, incrementDescAndOpt } = this.calculateQuestionNoAndFlag();
                    this.assignQuestionNumbers(gptQuestion, questionNo);
                    if (incrementDescAndOpt) {
                        this.adjustDescriptionsAndOptions(gptQuestion);
                    } else {
                        this.setInitialDescriptionAndOptionCounts(gptQuestion);
                        this.questions = gptQuestion;
                    }
                    this.$bvModal.hide("gpt-questions-modal");
                } else {
                    this.errorMsg = "Invalid text format";
                }
            } catch (error) {
                this.errorMsg = "Invalid text format";
            }
        },

        calculateQuestionNoAndFlag() {
            let questionNo = 1;
            let incrementDescAndOpt = false;
            if (this.questions.length > 0) {
                questionNo = this.questions[this.questions.length - 1].questionNo + 1;
                incrementDescAndOpt = true;
            }
            return { questionNo, incrementDescAndOpt };
        },

        assignQuestionNumbers(gptQuestion, questionNo) {
            gptQuestion.forEach((question, index) => {
                question.questionNo = questionNo + index;
            });
        },

        adjustDescriptionsAndOptions(gptQuestion) {
            this.adjustDescriptions(gptQuestion);
            this.adjustOptions(gptQuestion);
            this.updateDescriptionAndOptionCounts();
            this.questions.push(...gptQuestion);
        },

        adjustDescriptions(gptQuestion) {
            const descriptionCount = gptQuestion[0].descriptions.length;
            if (this.descriptionCount !== descriptionCount) {
                if (this.descriptionCount > descriptionCount) {
                    this.addExtraDescriptions(gptQuestion, descriptionCount);
                } else {
                    this.addExtraDescriptionsToExistingQuestions(descriptionCount);
                }
            }
        },

        addExtraDescriptions(gptQuestion, descriptionCount) {
            for (let index = descriptionCount; index < this.descriptionCount; index++) {
                gptQuestion.forEach((question) => {
                    question.descriptions.push({
                        title: "",
                        tts: "",
                        type: "text",
                        image: null,
                    });
                });
            }
        },

        addExtraDescriptionsToExistingQuestions(descriptionCount) {
            for (let index = this.descriptionCount; index < descriptionCount; index++) {
                this.questions.forEach((question) => {
                    question.descriptions.push({
                        title: "",
                        tts: "",
                        type: "text",
                        image: null,
                    });
                });
            }
        },

        adjustOptions(gptQuestion) {
            const optionCount = gptQuestion[0].options.length;
            if (this.optionCount !== optionCount) {
                if (this.optionCount > optionCount) {
                    this.addExtraOptions(gptQuestion, optionCount);
                } else {
                    this.addExtraOptionsToExistingQuestions(optionCount);
                }
            }
        },

        addExtraOptions(gptQuestion, optionCount) {
            for (let index = optionCount; index < this.optionCount; index++) {
                gptQuestion.forEach((question) => {
                    question.options.push({
                        title: "",
                        tts: "",
                        type: "text",
                        image: null,
                    });
                });
            }
        },

        addExtraOptionsToExistingQuestions(optionCount) {
            for (let index = this.optionCount; index < optionCount; index++) {
                this.questions.forEach((question) => {
                    question.options.push({
                        title: "",
                        tts: "",
                        type: "text",
                        image: null,
                    });
                });
            }
        },

        updateDescriptionAndOptionCounts() {
            this.descriptionCount = this.questions[0].descriptions.length;
            this.optionCount = this.questions[0].options.length;
        },

        setInitialDescriptionAndOptionCounts(gptQuestion) {
            this.descriptionCount = gptQuestion[0].descriptions.length;
            this.optionCount = gptQuestion[0].options.length;
        },

        onFocus(event) {
            const target = event.target;
            target.style.background = '#FFFFFF';
            target.style.position = 'absolute';
            target.style.top = 0;
            target.style.left = 0;
            target.style.width = '225px';
            target.style.zIndex = '10';
            target.style.minHeight = '38px';
            target.style.height = 'auto';
            target.style.padding = '20px';
            target.style.fontSize = '14px';
            target.style.whiteSpace = 'normal';
            target.style.overflow = 'auto';
            target.style.border = '1px solid #ebe9f1';
        },

        onBlur(event, index, name, childIndex, childName) {
            const target = event.target;
            target.style.background = '';
            target.style.position = '';
            target.style.top = '';
            target.style.left = '';
            target.style.width = '';
            target.style.minHeight = '';
            target.style.zIndex = '';
            target.style.height = '';
            target.style.padding = '';
            target.style.fontSize = '';
            target.style.whiteSpace = 'nowrap';
            target.style.overflow = 'hidden';
            target.style.border = '';
            this.updateQuestionValues(event, index, name, childIndex, childName);
        },

        stripHtml(html) {
            const htmlWithSpaces = html.replace(/&nbsp;/g, ' ');
            return htmlWithSpaces.replace(/<[^>]*>?/gm, '');
        },

        updateQuestionValues(event, index, name, childIndex, childName) {
            const innerHtml = event.target.innerHTML;
            const text = this.stripHtml(innerHtml);
            if (name === 'title') {
                this.questions[index].title = text;
            }

            if (name === 'titleTts') {
                this.questions[index].titleTts = text;
            }

            if (name === 'desc') {
                if (childName === 'title') {
                    this.questions[index].descriptions[childIndex].title = text;
                }

                if (childName === 'tts') {
                    this.questions[index].descriptions[childIndex].tts = text;
                }
            }

            if (name === 'opt') {
                if (childName === 'title') {
                    this.questions[index].options[childIndex].title = text;
                }

                if (childName === 'tts') {
                    this.questions[index].options[childIndex].tts = text;
                }
            }
        },

        onDragStart(event) {
            this.dragIndex = event.oldIndex;
        },

        onDragEnd(event) {
            this.dropIndex = event.newIndex;
            this.drag = false;
            this.questions.forEach((question, index) => {
                question.questionNo = index + 1;
            });
        },

        updateStickTitle() {
            this.isStickyTitle = !this.isStickyTitle;
        },

        updateStickFunction() {
            this.isStickyNumber = !this.isStickyNumber;
        },

        duplicateQuestion(question) {
            let duplicateObject = question;
            let newQuestion = JSON.parse(JSON.stringify(duplicateObject));
            delete newQuestion._id;
            newQuestion.questionNo =
                this.questions[this.questions.length - 1].questionNo + 1;
            this.questions.push(newQuestion);
        },

        hideFields() {
            this.isFieldDropdown = false;
        },

        hideShowField() {
            this.isFieldDropdown = !this.isFieldDropdown;
        },

        async handleBeforeUnload(event) {
            let updatedQuestion = await this.updateQuestionsChanges();
            let newQuestion = await this.newQuestion();
            if (updatedQuestion.length !== 0 || newQuestion.length !== 0) {
                const message =
                    "You have unsaved changes. Are you sure you want to leave?";
                event.returnValue = message;
                return message;
            }
        },

        updateQuestionsChanges() {
            const updatedArray = this.questions;
            let updateQuestion = [];
            for (let i = 0; i < this.originalQuestions.length; i++) {
                const originalObj = this.originalQuestions[i];
                const updatedObj = updatedArray.find(
                    (obj) => obj._id === originalObj._id
                );
                if (
                    updatedObj &&
                    JSON.stringify(originalObj) !== JSON.stringify(updatedObj)
                ) {
                    updateQuestion.push(updatedObj);
                }
            }
            return updateQuestion;
        },

        newQuestion() {
            const newQuestion = this.questions.filter(
                (obj) => obj._id === undefined
            );
            return newQuestion;
        },

        async saveQuestions() {
            let updatedQuestion = await this.updateQuestionsChanges();
            let newQuestion = await this.newQuestion();

            if (updatedQuestion.length === 0 && newQuestion.length === 0) {
                return "No changes or new questions found.";
            } else {
                const payload = {
                    updateQuestions: updatedQuestion,
                    newQuestions: newQuestion,
                    questions: this.questions,
                    levelId: this.$route.params.levelId,
                };
                try {
                    const res = await axios.put(
                        process.env.VUE_APP_API_URL + `/questions`,
                        payload,
                        {
                            headers: {
                                Authorization: this.bearerToken,
                            },
                        }
                    );
                    this.$bvModal.hide("save-question-modal");
                    await this.getQuestions();
                    this.alertMessageToast = res.data.message;
                    this.showToast("success");
                } catch (error) { }
            }
        },
        confirmDelete() {
            const selectedQuestions = this.questions.filter(
                (question) => question.checkbox
            ).length;
            if (!selectedQuestions) {
                return;
            }

            let msg =
                selectedQuestions > 1
                    ? "Do You want to delete questions!"
                    : "Do You want to delete question!";
            Swal.fire({
                title: "Are you sure?",
                text: msg,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteQuestions();
                }
            });
        },
        toggleSelectedQuestion() {
            this.tableCheckbox = false;
        },
        checkUncheckQuestion() {
            this.questions.forEach((question) => {
                question.checkbox = this.tableCheckbox;
            });
        },
        addDescription() {
            this.questions.forEach((question) => {
                question.descriptions.push({
                    title: "",
                    tts: "",
                    type: "text",
                    image: null,
                });
            });
            this.descriptionCount = this.descriptionCount + 1;
        },
        removeDescription(index) {
            const indexToRemove = index;

            this.questions.forEach((question) => {
                if (
                    question.descriptions &&
                    question.descriptions.length > indexToRemove
                ) {
                    question.descriptions.splice(indexToRemove, 1);
                }
            });
            this.descriptionCount = this.descriptionCount - 1;
        },
        addOption() {
            this.questions.forEach((question) => {
                question.options.push({
                    title: "",
                    tts: "",
                    type: "text",
                    image: null,
                    correct: false,
                });
            });
            this.optionCount = this.optionCount + 1;
        },
        removeOption(index) {
            const indexToRemove = index;

            this.questions.forEach((question) => {
                if (
                    question.options &&
                    question.options.length > indexToRemove
                ) {
                    question.options.splice(indexToRemove, 1);
                }
            });
            this.optionCount = this.optionCount - 1;
        },
        async addQuestions(question) {
            try {
                const payload = {
                    levelId: this.$route.params.levelId,
                    questions: [question],
                };
                await axios.post(
                    process.env.VUE_APP_API_URL + "/questions",
                    payload,
                    {
                        headers: {
                            Authorization:
                                "Bearer " + localStorage.getItem("_t"),
                        },
                    }
                );
                await this.getQuestions();
            } catch (error) { }
        },
        async addNewQuestion() {
            let newObject = await this.createObject();
            let newQuestion = JSON.parse(JSON.stringify(newObject));
            this.questions.push(newQuestion);
        },
        createObject() {
            let questionNo = this.questions.length + 1;
            if (this.questions.length > 0) {
                questionNo =
                    this.questions[this.questions.length - 1].questionNo + 1;
            }

            const obj = {
                checkbox: false,
                theme: "Basic",
                questionNo: questionNo,
                questionType: "MCQs",
                title: "",
                titleTts: "",
                descriptions: [],
                options: [],
            };

            for (let i = 0; i < this.descriptionCount; i++) {
                obj.descriptions.push({
                    title: "",
                    tts: "",
                    type: "text",
                    image: [],
                });
            }

            for (let i = 0; i < this.optionCount; i++) {
                obj.options.push({
                    title: "",
                    tts: "",
                    type: "text",
                    image: "",
                    correct: false,
                });
            }

            return obj;
        },
        async getQuestions() {
            this.show = true;
            const payload = {};
            payload.search = this.searchQuery;
            try {
                const res = await axios.get(
                    process.env.VUE_APP_API_URL +
                    `/questions/${this.$route.params.levelId}/MCQs`,
                    {
                        headers: {
                            Authorization: this.bearerToken,
                        },
                    }
                );
                if (res.data && res.data.questions.length > 0) {
                    this.descriptionCount =
                        res.data.questions[0].descriptions.length;
                    this.optionCount =
                        res.data.questions[0].options.length;
                }
                this.questions = res.data.questions;
                this.originalQuestions = JSON.parse(
                    JSON.stringify(res.data.questions)
                );
                this.show = false;
            } catch (error) {
                this.show = false;
            }
        },
        showToast(variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Notification",
                    icon: "BellIcon",
                    text: this.alertMessageToast,
                    variant,
                },
            });
        },
        async fetch() {
            await this.getQuestions();
            await this.fetchThemes();
        },
        async fetchThemes(page = 1) {
            const payload = {};
            try {
                const res = await axios.post(
                    process.env.VUE_APP_API_URL + `/themes?page=${page}`,
                    payload,
                    {
                        headers: {
                            Authorization: this.bearerToken,
                        },
                    }
                );
                this.themes = res.data.themes.data;
            } catch (error) { }
        },
        async deleteQuestions() {
            const selectedIds = this.questions
                .filter((question) => question.checkbox && question._id)
                .map((question) => question._id);

            const payload = {
                ids: selectedIds,
            };
            try {
                await axios.post(
                    process.env.VUE_APP_API_URL + `/questions/delete`,
                    payload,
                    {
                        headers: {
                            Authorization: this.bearerToken,
                        },
                    }
                );
                this.questions = this.questions.filter(
                    (question) => !question.checkbox
                );
                this.selectedQuestions = [];
                this.tableCheckbox = false;
            } catch (error) { }
        },
    },
};
</script>
